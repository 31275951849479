/* Estilo principal do container */
.container-contacts {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 80vh;
  background-color: var(--dark-blue);
  padding-top: 60px; /* Aumenta o espaço em relação ao header */
}

/* Estilo do box de contato */
.div-contatcs {
  width: 70%;
  max-height: 85vh;
  background: linear-gradient(180deg, var(--dark-green-1), var(--dark-green-2) 100%);
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}

/* Estilo do título */
.contacts-title {
  color: var(--light-cyan-3);
  font-size: x-large;
  margin-bottom: 20px;
  text-align: center;
}

.form-div-send-email {
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Formulário */
.form-div-send-email form {
  width: 90%;
  max-width: 600px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  background: var(--dark-green-1);
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  margin-top: 20px;
}

/* Título do formulário */
.form-title {
  font-size: 1.5rem;
  color: var(--light-cyan-2);
  margin-bottom: 15px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

/* Labels e inputs */
.form-div-send-email label {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  color: var(--light-cyan-2);
  font-family: 'Orbitron', sans-serif;
}

.form-div-send-email label input,
.form-div-send-email label textarea {
  margin-top: 5px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--light-cyan-3);
  background: var(--dark-green-1);
  color: var(--light-cyan-2);
  font-size: 1rem;
  outline: none;
  transition: box-shadow 0.3s ease;
}

.form-div-send-email label input:focus,
.form-div-send-email label textarea:focus {
  box-shadow: 0 0 8px var(--light-cyan-3);
  background-color: var(--dark-green-2);
}

textarea {
  resize: none;
  height: 100px;
}

/* Botão de envio */
.send-button {
  margin-top: 10px;
  background: var(--dark-green-1);
  color: var(--light-cyan-3);
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  text-transform: uppercase;
  border: none;
  cursor: not-allowed;
  transition: transform 0.3s ease, background 0.3s ease;
  font-family: 'Orbitron', sans-serif;
}

.send-button:enabled {
  cursor: pointer;
  background: var(--light-cyan-2);
}

.send-button:enabled:hover {
  transform: scale(1.05);
}

/* Mensagem de espera */
.form-div-send-email .wait-message {
  font-size: 1.2rem;
  color: var(--light-cyan-3);
  text-align: center;
  padding: 20px;
}

/* Ícones de redes sociais */
.div-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.icons {
  width: 40px;
  height: auto;
  transition: transform 0.3s ease;
}

.icons:hover {
  transform: scale(1.3);
}

/* Responsividade para telas menores */
@media (max-width: 1024px) {
  .div-contatcs {
    width: 80%;
    padding: 15px;
  }

  .form-div-send-email {
    width: 100%;
  }

  .contacts-title {
    font-size: 1.5rem;
  }

  .form-div-send-email form {
    max-width: 90%;
    padding: 15px;
  }
}

/* Ajuste para telas pequenas */
@media (max-width: 768px) {
  .div-contatcs {
    width: 90%;
    padding: 10px;
    max-height: none; /* Remove a limitação de altura */
  }

  .form-div-send-email {
    width: 100%;
    max-height: 70vh; /* Limita a altura visível para não ocupar toda a tela */
  }

  .form-div-send-email form {
    width: 100%;
    max-width: none;
    display: flex;
    flex-direction: column;
    overflow-y: visible; /* Garante que o formulário e botão fiquem visíveis */
  }

  .contacts-title {
    font-size: 1.2rem;
  }

  .send-button {
    position: static; /* Garante que o botão não seja afetado por outros estilos */
    margin-bottom: 20px; /* Adiciona espaço abaixo do botão para evitar sobreposição */
  }
}


@media (max-width: 480px) {
  .contacts-title {
    font-size: 1rem;
  }

  .form-title {
    font-size: 1.2rem;
  }

  .send-button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .icons {
    width: 25px;
  }
}
