.container-header {
  z-index: 99;
  position: fixed;
  width: 100vw;
  background: linear-gradient(180deg, var(--dark-green-1), 35%, var(--dark-green-2) 100%);
  align-items: center;
  padding: 5px 0px;
  font-family: 'Roboto', sans-serif;
}

.options-menu {
  padding: 5px;
  margin: 5px;
  background: linear-gradient(180deg, var(--dark-green-2), 35%, var(--dark-green-1) 100%);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10%;
}

.links-menu {
  width: 100vw;
  display: flex;
  justify-content: space-around;
}

.link-menu {
  text-decoration: none;
  color: var(--light-cyan-2);
  font-size: large;
  text-align: center;
  transition: transform 0.3s ease;
  padding: 5px;
}

.options-menu:hover {  
  transform: scale(1.1);
}

.options-menu.active {
  color: var(--light-cyan-3);
}

.link-menu.active:hover {
  transform: none;
}

.link-menu.active {
  color: var(--light-cyan-3);
}

.link-menu.active:hover {
  transform: none;
}

.close-menu {
  display: flex;
  justify-content: center;
}

.btn-menu-hamburguer {
  display: none;
}

@media screen and (max-width: 768px) {
  .btn-menu-hamburguer {
    display: block;
    z-index: 99;
    background-color: var(--dark-green-1);
    border: none;
    cursor: pointer;
    color: var(--light-cyan-2);
  }

  .container-header {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    border-radius: 10px;
    animation-duration: 1s;
    margin-left: -100vw;
    padding-bottom: 10px;
  }
  
  .links-menu {
    padding-top: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@keyframes showSidebar {
  from {
    margin-left: -100vw;
  }
  top {
    margin-left: -10vw;
  }
}