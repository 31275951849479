.container-projects {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.card-container {
  display: flex;
  transition: transform 0.5s ease, opacity 0.5s ease;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-wrap: wrap; /* Permite que os cards se ajustem em linhas */
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  background: linear-gradient(180deg, var(--dark-green-3), var(--dark-green-2));
  z-index: 99;
  width: 30px;
  position: fixed;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  opacity: 0.9;
}

.arrow:active {
  background-color: var(--dark-green-1);
}

.left-arrow {
  left: 0px;
}

.right-arrow {
  right: 0px;
}

.render-projects {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.card-projects {
  margin: 15px;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, var(--dark-green-2), var(--dark-green-1));
  box-shadow: 0px 6px 22px -7px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  transition: box-shadow 1s ease, transform 1s ease;
  transform: scale(0.9);
  opacity: 0.9;
}

.card-projects:hover {
  transform: scale(1.0);
  box-shadow: 0px 6px 15px -7px rgba(0, 0, 0, 0.8);
  opacity: 1;
}

.project-image {
  max-width: 260px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.link-repository-project, .link-deploy-project {
  color: var(--light-cyan-1);
  font-size: medium;
}

.link-repository-project:hover, .link-deploy-project:hover {
  text-decoration: none;
  color: var(--light-cyan-2);
}

.project-link, .project-title {
  text-align: center;
  padding-bottom: 20px;
  font-size: large;
  transition: transform 0.2s ease, color 0.2s ease;
  color: var(--light-cyan-1);
}

.project-link {
  font-size: medium;
}

.project-link:hover, .project-title:hover {
  transform: scale(1.05);
  color: var(--light-cyan-2);
}

.description {
  padding: 0px 15px;
  text-align: justify;
  line-height: 1.5;
  color: var(--light-cyan-2);
}

.description p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
  letter-spacing: -0.5px;
}

/* Responsividade */
@media screen and (max-width: 1024px) {
  .card-projects {
    max-width: 70%;
  }

  .project-image {
    max-width: 220px;
  }
}

@media screen and (max-width: 768px) {
  .container-projects {
    flex-direction: column;
    overflow-y: auto;
    scroll-behavior: smooth;
  }

  .card-container {
    overflow-y: auto;
    flex-wrap: wrap;
    gap: 10px;
  }

  .arrow {
    display: none; /* Esconde setas em dispositivos móveis */
  }

  .card-projects {
    max-width: 80%;
    margin: 10px;
  }

  .project-image {
    max-width: 180px;
  }

  .project-link, .project-title {
    font-size: medium;
  }

  .description {
    font-size: small;
  }
}

@media screen and (max-width: 480px) {
  .card-projects {
    max-width: 90%;
  }

  .project-image {
    max-width: 150px;
  }

  .project-link, .project-title {
    font-size: small;
  }

  .description {
    font-size: 0.9rem;
    padding: 0 10px;
  }
}
