.container-about-me {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
}

.text-div-about-me {
  z-index: 3;
  background: linear-gradient(180deg, var(--dark-green-2), 5%, var(--dark-green-2) 100%);
  border-radius: 5px;
  height: auto;
  width: 60vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  margin-top: 40px;
  margin-bottom: 10px;
}

.about-me-title {
  color: var(--light-cyan-2);
  padding-top: 5px;
  padding-right: 300px;
  padding-bottom: 15px;
  font-size: x-large;
  text-align: center;
}

.about-me-text {
  color: var(--light-cyan-2);
  padding: 20px;
  font-size: large;
  background-color: var(--dark-blue); /* Cor do balão */
  border-radius: 20px;
  margin: 20px 10px; /* Espaçamento geral */
  max-width: 600px;
  position: relative;
}

/* Balões para a esquerda */
.about-me-text:nth-of-type(odd) {
  margin-left: 500;
  margin-right: auto; /* Empurra o balão para a esquerda */
  text-align: left;
}

/* Balões para a direita */
.about-me-text:nth-of-type(even) {
  margin-left: auto; /* Empurra o balão para a direita */
  margin-right: 500;
  text-align: right;
}

.paragraph {
  line-height: 150%;
}

@media (max-width: 480px) {
  .about-me-text p,
  .about-me-text li {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}