:root {
  --black: #1d1c1c;
  --dark-green-1: #264653;
  --dark-green-2: #2d5261;
  --dark-green-3: #396779;
  --light-cyan-3: #416979;
  --light-cyan-2: #c7c9ca;
  --light-cyan-1: #959697;

}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; /* Evita o scroll horizontal */
  box-sizing: border-box; /* Inclui as bordas no cálculo de largura/altura */
}

* {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  list-style: none;
  border: 0;
}

body {
  width: 100vw;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../assets/portfolio-image.png');
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--dark-green-1); 
  border: 1px solid var(--light-cyan-3);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: var(--dark-green-1); 
}